<template>
  <v-card outlined id="user-logs" max-width="1400" class="mx-auto rounded-lg">
    <v-expansion-panels
      :value="showDataTable ? 0 : null"
      @change="$emit('showDataTable', $event === 0)"
      tile
      flat
      accordion
      :readonly="companyView"
    >
      <v-expansion-panel :readonly="companyView">
        <v-expansion-panel-header :hide-actions="companyView" class="pa-0 pr-6">
          <v-card-title>
            <slot name="table-title"></slot>
          </v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-data-table
            :headers="table_headers"
            :items="table_records"
            fixed-header
            :loading="loadingLogs"
            mobile-breakpoint="900"
            :items-per-page="itemsPerPage"
            :footer-props="{ itemsPerPageOptions: itemsOptions }"
            :height="tableHeight"
            sort-by="date"
            :sort-desc="true"
            @update:options="tableOptions = $event"
            show-expand
            :single-expand="false"
            item-key="index"
          >
            <template v-slot:no-data> {{ $ml.get("no_data") }} </template>
            <template
              v-if="companyView"
              v-slot:[`header.author_user`]="{ header }"
            >
              {{ $ml.get(header.value) }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    v-text="'mdi-help-circle'"
                  />
                </template>
                <span>{{ $ml.get("author_description") }}</span>
              </v-tooltip>
            </template>
            <template v-if="companyView" v-slot:[`item.author_user`]="{ item }">
              <div class="d-flex align-center">
                <v-avatar size="28" class="mr-2" v-if="isNotSystem(item)">
                  <img
                    :src="
                      getUserPhoto(
                        item.author_user ||
                          item.status.target_user ||
                          item.status.logged_out_user
                      )
                    "
                    :alt="item.username"
                  />
                </v-avatar>
                <span
                  v-if="
                    item.status.code === backendLogCodes.LOST_USER_SINGLE_ACCESS
                  "
                >
                  {{ item.status.author_user }}
                </span>
                <span v-else-if="isGroupCode(item.status.code)">
                  {{ item.author_user || $ml.get("user_not_found") }}
                </span>
                <span v-else>
                  {{
                    item.author_user ||
                    item.status.logged_out_user ||
                    $ml.get("system")
                  }}
                </span>
              </div>
            </template>
            <template v-slot:[`header.event`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`item.event`]="{ item }">
              <v-chip small>
                {{ item.event }}
              </v-chip>
            </template>
            <template v-slot:[`header.description`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span v-if="loginAttemptCodes.includes(item.status.code)">
                {{ $ml.get(item.status.code.toLowerCase()) }}
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.ADD_NEW_USER"
              >
                <span class="font-weight-medium">
                  {{ $ml.get("system") }}
                </span>
                <span>
                  {{ $ml.get("add_new_user") }}
                  <b>{{ getUserEmail(item.status.target_user) }}</b>
                </span>
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.DELETE_USER"
              >
                <span class="font-weight-medium">
                  {{ $ml.get("system") }}
                </span>
                <span>
                  {{ $ml.get("delete_user") }}
                  <b>{{ getUserEmail(item.status.target_user) }}</b>
                </span>
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.UPDATE_USER"
              >
                <span class="font-weight-medium">
                  {{ $ml.get("system") }}
                </span>
                <span>
                  {{ $ml.get("update_user") }}
                  <b>{{ getUserEmail(item.status.target_user) }}</b>
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.USER_LOGOUT_SUCCESS
                "
              >
                {{ item.event }}
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.LOGOUT_SUCCESS"
              >
                <span class="font-weight-medium"> {{ item.author_user }}</span>
                {{ $ml.get("force_logout_description") }}
                <span class="font-weight-medium">
                  {{ item.status.logged_out_user }}</span
                >
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.CHANGE_USER_PASSWORD
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("changed_the_password_of") }}
                <span class="font-weight-medium">
                  {{ item.status.target_user }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.CHANGE_OWN_PASSWORD
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("change_own_password") }}
              </span>
              <span
                v-else-if="
                  [
                    backendLogCodes.ADVANCED_AUTO_LOGOUT,
                    backendLogCodes.AUTO_LOGOUT,
                  ].includes(item.status.code)
                "
              >
                {{ $ml.get("closed_office") }}
              </span>
              <span
                v-else-if="
                  item.status.code ===
                  backendLogCodes.AUTO_LOGOUT_AFTER_CLOSING_WINDOW
                "
              >
                {{ $ml.get("closed_office") }} ({{
                  $ml.get("auto_logout_after_closing_window_description")
                }})
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.SINGLE_ACCESS_LOGOUT
                "
              >
                {{ $ml.get("end_of_single_access") }}
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.PONTOMAIS_AUTO_LOGOUT ||
                  item.status.code === backendLogCodes.SENIOR_AUTO_LOGOUT
                "
              >
                {{ $ml.get("pontomais_auto_logout_description") }}
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.ADD_USERS_TO_GROUP
                "
              >
                {{ $ml.get("add_users_to_group") }}
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}:
                </span>
                <span class="font-weight-medium">
                  {{ getUsersEmails(item.status.users) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.REMOVE_USERS_FROM_GROUP
                "
              >
                {{ $ml.get("remove_users_from_group") }}
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}:
                </span>

                <span class="font-weight-medium">
                  {{ getUsersEmails(item.status.users) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.ADD_GROUPS_TO_USER
                "
              >
                <span class="font-weight-medium">
                  {{ getUsersEmails(item.status.users) }}
                </span>
                {{ $ml.get("user_added_on_shifts") }}:
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.REMOVE_GROUPS_FROM_USER
                "
              >
                <span class="font-weight-medium">
                  {{ getUsersEmails(item.status.users) }}
                </span>
                {{ $ml.get("user_removed_from_shifts") }}:
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.ADDED_GROUP_RULES
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("user_added_group_rules") }}:
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.REMOVED_GROUP_RULES
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("user_removed_group_rules") }}:
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}
                </span>
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.DELETED_GROUPS"
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("user_deleted_groups") }}:
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.UPDATED_GROUP_RULES
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("user_updated_group_rules") }}
                <span class="font-weight-medium">
                  {{ getGroupsNames(item.status.group) }}
                </span>
                <span
                  class="font-weight-medium"
                  v-if="translateGroupDescription(item.status.message, $ml)"
                >
                  {{ $ml.get("to") }}
                  {{ translateGroupDescription(item.status.message, $ml) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code ===
                  backendLogCodes.USER_REQUEST_SINGLE_ACCESS
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("requested_single_access") }}
                <span class="text-decoration-underline">
                  {{ $ml.get("to_admins") }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code ===
                  backendLogCodes.USER_REQUEST_PASSWORD_CHANGE
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                {{ $ml.get("requested_password_change_description") }}
                <span class="text-decoration-underline">
                  {{ $ml.get("to_admins") }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.ADD_VACATION_TO_USER
                "
              >
                <span>
                  {{ $ml.get("add_vacation_to_user") }}
                </span>
                <span class="font-weight-medium">
                  "{{ item.status.target_user }}".
                </span>
                {{ $ml.get("vacation_period") }}
                <span>
                  {{ $ml.get("from").toLowerCase() }}
                  {{ formatDateUTC(item.status.start_date) }}
                  {{ $ml.get("until") }}
                  {{ formatDateUTC(item.status.end_date) }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.REMOVE_VACATION_FROM_USER
                "
              >
                {{ $ml.get("remove_vacation_from_user") }}
                <span class="font-weight-medium">
                  {{ item.status.target_user }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code ===
                  backendLogCodes.RECEIVE_USER_SINGLE_ACCESS
                "
              >
                <span>
                  {{ item.status.author_user || $ml.get("not_found") }}
                  {{ $ml.get("granted_single_access") }}
                  {{
                    item.status.data && item.status.data.access_time
                      ? `${$ml.get("for")} ${
                          item.status.data.access_time
                        } ${$ml.get(item.status.data.time_unit)}`
                      : $ml.get("forIndeterminateTime")
                  }}
                  {{ $ml.get("to") }}
                  <b>{{ item.status.target_user }} </b>
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.LOST_USER_SINGLE_ACCESS
                "
              >
                <span>
                  {{ item.status.author_user || $ml.get("not_found") }}
                  {{
                    `${$ml
                      .get("removed_single_access_from")
                      .toLowerCase()} ${$ml.get("from").toLowerCase()} `
                  }}
                  <b>{{ item.status.target_user }}</b>
                </span>
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.ACTIVATE_SSO"
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                <span>
                  {{ $ml.get("user_activate_sso") }}
                </span>
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.DISABLE_SSO"
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                <span>
                  {{ $ml.get("user_disable_sso") }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code ===
                  backendLogCodes.CANCEL_ACTIVATION_SSO_ASYNC
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                <span>
                  {{ $ml.get("cancel_activation_sso_async") }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.ACTIVATE_SSO_ASYNC
                "
              >
                <span class="font-weight-medium">
                  {{ item.author_user }}
                </span>
                <span>
                  {{ $ml.get("user_activate_sso_async") }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.ADD_ADMIN_PERMISSION
                "
              >
                <span>
                  <b>{{ item.status.target_user }}</b>
                  {{ $ml.get("has_been_added_admin") }}
                </span>
              </span>
              <span
                v-else-if="
                  item.status.code === backendLogCodes.REMOVE_ADMIN_PERMISSION
                "
              >
                <span>
                  <b>{{ item.status.target_user }}</b>
                  {{ $ml.get("is_no_longer_an_administrator") }}
                </span>
              </span>
              <span
                v-else-if="item.status.code === backendLogCodes.CUSTOMIZE_LOGIN"
              >
                <div v-if="item.status.data" class="link-container">
                  <span v-for="key in Object.keys(item.status.data)" :key="key">
                    <b>{{ $ml.get(key) }}:</b>
                    <span v-if="['logo_width', 'logo_height'].includes(key)">
                      {{ item.status.data[key] || $ml.get("automatic") }}</span
                    >
                    <span v-else>{{ item.status.data[key] }}</span>
                    <br />
                  </span>
                </div>
                <div v-else>-</div>
              </span>
            </template>
            <template v-slot:[`header.date`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`header.browser`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`item.date`]="{ item }">
              {{ formatDateAndHour(item.created_at, true) }}
            </template>
            <template v-slot:[`header.ip`]="{ header }">
              {{ $ml.get(header.value) }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    v-text="'mdi-help-circle'"
                  />
                </template>
                <span>{{ $ml.get("ip_address_description") }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.operational_system`]="{ header }">
              {{ $ml.get(header.value) }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    v-text="'mdi-help-circle'"
                  />
                </template>
                <span>{{ $ml.get("operational_system_description") }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.start`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="d-flex justify-center align-center flex-nowrap"
                  >
                    {{ $ml.get(header.value) }}
                    <v-icon class="ml-1" small> mdi-help-circle </v-icon>
                  </div>
                </template>
                <div style="max-width: 450px">
                  {{ $ml.get("access_time_start") }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`header.end`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="d-flex justify-center align-center flex-nowrap"
                  >
                    {{ $ml.get(header.value) }}
                    <v-icon class="ml-1" small> mdi-help-circle </v-icon>
                  </div>
                </template>
                <div style="max-width: 450px">
                  {{ $ml.get("access_time_end") }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`header.duration`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="d-flex justify-center align-center flex-nowrap"
                  >
                    {{ $ml.get(header.value) }}
                    <v-icon class="ml-1" small> mdi-help-circle </v-icon>
                  </div>
                </template>
                <div style="max-width: 450px">
                  {{
                    isMicrosoft
                      ? $ml
                          .get("access_time_duration")
                          .replace("Google", "Microsoft")
                      : $ml.get("access_time_duration")
                  }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`header.day`]>
              {{ $ml.get("date") }}
            </template>
            <template v-slot:[`header.records`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`item.start`]="{ item }">
              <div :class="item.startFix ? 'incomplete' : 'complete'">
                {{ item.start }}
              </div>
            </template>
            <template v-slot:[`item.end`]="{ item }">
              <div :class="item.endFix ? 'incomplete' : 'complete'">
                {{ item.end }}
              </div>
            </template>
            <template v-slot:[`item.duration`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="item.state != 'COMPLETE' && on"
                    :class="`${
                      item.durFix ? 'incomplete' : 'complete'
                    } d-flex justify-center align-center flex-nowrap`"
                  >
                    {{ item.duration }}
                    <v-icon v-if="item.state != 'COMPLETE'" class="ml-1" small>
                      mdi-help-circle
                    </v-icon>
                  </div>
                </template>
                <div style="max-width: 450px">
                  {{
                    item.state == "MISSING_DATA"
                      ? $ml.get("access_time_duration_missing_data")
                      : item.state == "NOT_FINISHED"
                      ? $ml.get("access_time_duration_not_finished")
                      : item.state == "CANNOT_CALCULATE"
                      ? $ml.get("access_time_duration_cannot_calculate")
                      : ""
                  }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`item.records`]="{ item }">
              <span>
                <span v-if="item.records">
                  <span
                    v-for="(record, index) in item.records.slice(0, 10)"
                    :key="index"
                    class="mx-1"
                  >
                    <v-chip small class="my-1"
                      >{{ record.event }} {{ record.hour }}
                    </v-chip>
                  </span>
                </span>
                <span v-if="item.records && item.records.length > 10">
                  <v-chip small class="my-1">...</v-chip>
                </span>
              </span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pl-4 pt-4 pb-4 grey lighten-4"
              >
                <div v-if="expandableLogCodes.includes(item.status.code)">
                  <span
                    v-if="
                      item.status.code === customLogCodes.reports_access_time
                    "
                  >
                    <v-data-table
                      :headers="item.headers"
                      :items="item.records"
                      :hide-default-footer="
                        !item.records ||
                        (item.records && item.records.length <= 10)
                      "
                    >
                      <template v-slot:[`item.event`]="{ item }">
                        <v-chip small>
                          {{ item.event }}
                        </v-chip>
                      </template>
                    </v-data-table>
                  </span>
                </div>
              </td>
            </template>
            <template
              v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            >
              <v-icon
                v-if="expandableLogCodes.includes(item.status.code)"
                :class="[
                  'v-data-table__expand-icon',
                  { 'v-data-table__expand-icon--active': isExpanded },
                ]"
                @click.stop="expand(!isExpanded)"
                >$expand</v-icon
              >
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import {
  formatDateAndHour,
  formatDateUTC,
  formatHour,
  formatDateFullMonth,
  paging,
  tableHeight,
  translateGroupDescription,
} from "@/helpers/utils";

import {
  backendLogCodes,
  loginAttemptCodes,
  expandableLogCodes,
  customLogCodes,
} from "@/helpers/variables";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Reports",
  props: {
    companyView: { type: Boolean },
    records: { type: Array, default: () => [] },
    loadingLogs: { type: Boolean },
    showDataTable: { type: Boolean },
    headers: { type: Array },
  },

  data() {
    return {
      paging,
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
      loginAttemptCodes,
      backendLogCodes,
      expandableLogCodes,
      customLogCodes,
      tableOptions: null,
      defaultHeaders: [
        {
          value: "date",
          width: 180,
          sortable: false,
        },
        {
          value: "event",
          sortable: false,
        },
        {
          value: "author_user",
          sortable: false,
        },
        {
          value: "description",
          sortable: false,
        },
        {
          value: "ip",
          width: 170,
          sortable: false,
        },
        {
          value: "browser",
          width: 160,
          sortable: false,
        },
        {
          value: "operational_system",
          width: 186,
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["userDefaultImage", "users", "isMicrosoft"]),
    table_headers() {
      const headers = this.headers || this.defaultHeaders;
      if (this.companyView) {
        return headers;
      }
      return headers.filter((item) => item.value != "author_user");
    },
    table_records() {
      return this.records.map((record, index) => ({ ...record, index }));
    },
  },

  watch: {
    tableOptions() {
      this.$emit("tableOptions", this.tableOptions);
    },
  },

  methods: {
    ...mapActions(["setSnackbarDefault"]),
    formatDateAndHour,
    formatDateUTC,
    formatHour,
    formatDateFullMonth,
    translateGroupDescription,

    getUserPhoto(userEmail) {
      const user = this.users.find(({ email }) => email == userEmail);

      if (user) {
        return user.photo || this.userDefaultImage;
      }

      return this.userDefaultImage;
    },

    isNotSystem(item) {
      if (item.status.code === backendLogCodes.LOST_USER_SINGLE_ACCESS) {
        return item.status.target_user !== "System";
      } else if (item.author_user || item.status.logged_out_user) {
        return (item.author_user || item.status.logged_out_user) !== "System";
      } else {
        return false;
      }
    },

    isGroupCode(code) {
      return (
        code === backendLogCodes.ADD_GROUPS_TO_USER ||
        backendLogCodes.ADD_USERS_TO_GROUP ||
        backendLogCodes.REMOVE_GROUPS_FROM_USER ||
        backendLogCodes.REMOVE_USERS_FROM_GROUP
      );
    },

    getUserEmail(user) {
      return user.email || this.$ml.get("user_not_found");
    },

    getUsersEmails(users) {
      return users.length
        ? users.map((user) => user.email).join(", ")
        : this.$ml.get("user_not_found");
    },

    getGroupsNames(groups) {
      return groups.length
        ? groups.map((group) => group.name).join(", ")
        : this.$ml.get("not_found");
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.complete {
  padding: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--v-success-base);
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
}
.incomplete {
  padding: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--v-background-darken1);
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
}

.link-container {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
